<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >

          <template
            v-if="
              $permissionAbility(LEAVE_REQUESTS_APPLICATION_CREATE, permissions)
            "
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="flex-shrink-0 ml-1"
              variant="primary"
              @click="showApplicationCreateModal"
            >
              Apply For Employee
            </b-button>
          </template>
          <template v-else>
            <template
              v-if="$permissionAbility(LEAVE_REQUESTS_CREATE, permissions)"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="flex-shrink-0"
                variant="primary"
                @click="showModal"
              >
                Apply
              </b-button>
            </template>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        style-class="vgt-table striped table-custom-style"
        :line-numbers="false"
        mode="remote"
        :total-rows="totalRecords"
        :is-loading.sync="isLoading"
        :rows="rows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'id', type: 'desc' }],
        }"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- head -->
          <span v-if="props.column.field === 'name'">

            <template v-if="props.row?.user_id">

              <UserCard :user="props?.row?.user?.data" />

            </template>

          </span>

          <span v-if="props.column.field === 'leave_days_format'">
            <template v-if="props?.row?.leave_days">
              <b-badge variant="light-primary">{{
                  props?.row?.leave_days
              }}</b-badge>
            </template>
          </span>

          <span v-if="props.column.field === 'format_is_reconciliation'">
            <template v-if="props.row?.is_reconciliation">
              <b-badge
                class="ml-1"
                variant="light-warning"
              >
                Yes
              </b-badge>
            </template>
            <template v-else>
              <b-badge
                class="ml-1"
                variant="light-success"
              >
                No
              </b-badge>
            </template>
          </span>

          <div v-if="props.column.field === 'format_leave_type'">
            <template v-if="props.row?.leaveType">
              <b-badge
                class="ml-1"
                variant="light-warning"
              >
                {{ props.row?.leaveType?.data?.name }}
              </b-badge>
            </template>
          </div>

          <!-- Column: Status -->
          <span v-if="props.column.field === 'format_status'">
            <b-badge :variant="statusVariant(props.row?.status)">
              {{ props.row?.status }}
            </b-badge>

            <div>
              <template v-if="props.row?.remarks !== ''">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.top="'Remarks: '+props.row?.remarks"
                  variant="outline-primary"
                  class="btn-icon m-1"
                >
                  <feather-icon
                    class="btn-icon"
                    icon="InfoIcon"
                  />
                </b-button>
              </template>

              <template v-if="props.row?.reject_remarks">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.top="'Rejection Note: '+props.row?.reject_remarks"
                  variant="outline-primary"
                  class="btn-icon m-1"
                >
                  <feather-icon
                    class="btn-icon"
                    icon="InfoIcon"
                  />
                </b-button>
              </template>

              <template v-if="props?.row?.leave_request_file !== ''">
                <b-link
                  v-b-tooltip.hover.top="'Attached File'"
                  :href="props?.row?.leave_request_file"
                  target="_blank"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon m-1"
                  >
                    <feather-icon
                      class="btn-icon"
                      icon="FileIcon"
                    />
                  </b-button>
                </b-link>
              </template>

            </div>
          </span>

          <span v-if="props.column.field === 'action'">

            <template v-if="props.row?.status === PENDING">
              <template v-if="$permissionAbility(LEAVE_REQUESTS_EDIT, permissions)">
                <span @click="onShow(props.row)">
                  <feather-icon
                    v-b-tooltip.hover
                    icon="Edit2Icon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Edit"
                    size="16"
                  />
                </span>
              </template>

              <template
                v-if="$permissionAbility(LEAVE_REQUESTS_DELETE, permissions)"
              >
                <span @click="onDelete(props.row.id)">
                  <feather-icon
                    v-b-tooltip.hover
                    icon="TrashIcon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Delete"
                    size="16"
                  />
                </span>
              </template>
            </template>
            <template v-else>
              <b-badge variant="danger">N/A</b-badge>
            </template>

          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <!-- leave request -->
    <b-modal
      id="modal-leave-request-form"
      centered
      :title="
        modelType == 'editModel' ? 'Application Edit' : 'Apply Leave'
      "
      hide-footer
      no-close-on-backdrop
      @hidden="hiddenModal"
    >
      <validation-observer ref="leaveRequestValidation">
        <!-- validationForm -->
        <b-form @submit.prevent="validationForm">
          <!-- select Leave Type-->
          <b-form-group
            label="Leave Type"
            label-for="leave-type-id"
            class="required-label"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="leave type"
              vid="leave_type_id"
              rules="required"
            >
              <v-select
                id="leave-type-id"
                v-model="selectLeaveTypeId"
                placeholder="Select Leave Type"
                :options="leaveTypesOption"
                :reduce="(option) => option.id"
                label="name"
                :selectable="(option) => option.disable"
                @input="onChangeGetLeaveDates"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- select start date-->
          <b-form-group
            label="Start Date"
            label-for="start-date"
            class="required-label"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="start date"
              vid="start_date"
              rules="required"
            >
              <b-form-datepicker
                id="start-date"
                v-model="start_date"
                :state="errors.length > 0 ? false : null"
                locale="en-US"
                reset-button
                :date-disabled-fn="dateDisabledEmp"
                @context="onContextStartDate"
                @input="onChangeGetLeaveDates(); validateHalfDayOptions()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- select Half Day Leave switch-->
          <b-form-group
            label="Half Day Leave"
            label-for="start_date_half_day_leave_checkbox"
          >
            <b-form-checkbox
              :checked="isCheckedStartDaySwitch"
              name="start_date_half_day_leave_checkbox"
              switch
              inline
              @change="
                (isChecked) => {
                  onChangeStartDateSwitch(isChecked);
                  onChangeGetLeaveDates();
                  validateHalfDayOptions()
                }
              "
            />
          </b-form-group>

          <template v-if="isCheckedStartDaySwitch">
            <!-- select Half Day Leave-->
            <b-form-group
              label="Half Day Leave"
              label-for="start_date_half_day_leave"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="start_date_half_day_leave"
                vid="start_date_half_day_leave"
              >
                <v-select
                  id="start_date_half_day_leave"
                  v-model="selectStartDateHalfDayLeaveValue"
                  placeholder="Choose a option"
                  :options="startDateHalfDayLeaveOption"
                  :reduce="(option) => option.value"
                  @input="onChangeGetLeaveDates"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </template>

          <!-- select end date-->
          <b-form-group
            label="End Date"
            label-for="end-date"
            class="required-label"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="end date"
              vid="end_date"
              rules="required"
            >
              <b-form-datepicker
                id="end-date"
                v-model="end_date"
                :state="errors.length > 0 ? false : null"
                locale="en-US"
                reset-button
                :date-disabled-fn="dateDisabledEmp"
                @context="onContextEndDate"
                @input="onChangeGetLeaveDates(); validateHalfDayOptions()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- select Half Day Leave switch-->
          <b-form-group
            label="Half Day Leave"
            label-for="end_date_half_day_leave_checkbox"
          >
            <b-form-checkbox
              :checked="isCheckedEndDaySwitch"
              name="end_date_half_day_leave_checkbox"
              :disabled="endDateHalfDayLeaveCheckboxDisable"
              switch
              inline
              @change="
                (isChecked) => {
                  onChangeEndDateSwitch(isChecked);
                  onChangeGetLeaveDates();
                  validateHalfDayOptions()
                }
              "
            />
          </b-form-group>

          <template v-if="isCheckedEndDaySwitch">
            <!-- select Half Day Leave-->
            <b-form-group label-for="end_date_half_day_leave">
              <ValidationProvider
                v-slot="{ errors }"
                name="end_date_half_day_leave"
                vid="end_date_half_day_leave"
              >
                <v-select
                  id="end_date_half_day_leave"
                  v-model="selectEndDateHalfDayLeaveValue"
                  placeholder="Choose a option"
                  :options="endDateHalfDayLeaveOption"
                  :reduce="(option) => option.value"
                  @input="onChangeGetLeaveDates"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </template>

          <!-- leave calculate -->
          <template v-if="isLeaveCalculateSubmitLoading">
            <b-form-group>
              <div class="text-center">
                <b-spinner
                  variant="primary"
                  label="Text Centered"
                />
              </div>
            </b-form-group>
          </template>
          <template v-else>
            <b-form-group>
              <div>
                <p
                  v-if="leaveCount > 0"
                  class="mb-0"
                >
                  Days: {{ leaveCount }}
                </p>
                <p
                  v-if="sandwichLeaveCount > 0"
                  class="mb-0"
                >
                  Sandwich Days: {{ sandwichLeaveCount ?? 0 }}
                </p>
                <div
                  class="d-flex flex-wrap justify-content-start align-items-center"
                >
                  <p class="mb-0">
                    Dates:
                    <template v-if="leaveDates.length > 0">
                      <template v-for="(leaveDate, index) in leaveDates">
                        <b-badge
                          :key="index"
                          pill
                          variant="light-primary"
                        >
                          <template v-if="leaveDate">
                            {{ leaveDate | moment("ll") }}
                          </template>
                        </b-badge>
                      </template>
                    </template>
                    <template v-else>
                      <b-badge variant="light-primary">
                        Empty
                      </b-badge>
                    </template>
                  </p>
                </div>
              </div>
            </b-form-group>
          </template>

          <!-- remarks -->
          <b-form-group
            label="Reason"
            label-for="reason"
            class="required-label"
          >
            <validation-provider
              #default="{ errors }"
              name="reason"
              vid="reason"
              rules="required"
            >
              <b-form-textarea
                id="reason"
                v-model="remarks"
                :state="errors.length > 0 ? false : null"
                placeholder="Reason"
                rows="3"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- file -->

          <b-form-group
            label="File"
            label-for="file"
          >
            <validation-provider
              #default="{ errors }"
              name="file"
              rules="size:2048"
              vid="leave_request_file"
            >
              <b-form-file
                id="file"
                v-model="file"
                :state="errors.length > 0 ? false : null"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isLeaveRequestFormSubmitLoading">
            <b-button
              class="float-right"
              variant="primary"
              disabled
            >
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              class="float-right"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- leave application create -->
    <template
      v-if="$permissionAbility(LEAVE_REQUESTS_APPLICATION_CREATE, permissions)"
    >
      <b-modal
        id="modal-leave-application-create-form"
        centered
        title="Apply Leave"
        hide-footer
        no-close-on-backdrop
        @hidden="hiddenApplicationCreateModal"
      >
        <validation-observer ref="leaveApplicationCreateValidation">
          <!-- validationForm -->
          <b-form @submit.prevent="applicationCreateForm">
            <!-- select head  -->
            <b-form-group
              label="Employee"
              label-for="user-id"
              class="required-label"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="employee"
                vid="user_id"
                rules="required"
              >
                <v-select
                  id="user-id"
                  v-model="selectUserId"
                  placeholder="Select Employee"
                  :options="userIdOption"
                  :reduce="(country) => country.id"
                  label="name"
                  @input="onChangeEmployee"
                >
                  <template #option="data">
                    <UserSelect :user="data" />
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>

            <!-- select Leave Type-->
            <b-form-group
              label="Leave Type"
              label-for="employee-leave-type-id"
              class="required-label"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Leave Type"
                vid="leave_type_id"
                rules="required"
              >
                <v-select
                  id="employee-leave-type-id"
                  v-model="selectLeaveTypeId"
                  placeholder="Select Leave Type"
                  :options="employeeLeaveTypesOption"
                  :reduce="(option) => option.id"
                  label="name"
                  :selectable="(option) => option.disable"
                  :disabled="employeeLeaveTypesOption.length<=0"
                  @input="onChangeGetLeaveDates"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>

            <!-- select start date-->
            <b-form-group
              label="Start Date"
              label-for="start-date"
              class="required-label"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="start date"
                vid="start_date"
                rules="required"
              >
                <b-form-datepicker
                  id="start-date"
                  v-model="start_date"
                  :state="errors.length > 0 ? false : null"
                  locale="en-US"
                  reset-button
                  :date-disabled-fn="dateDisabled"
                  @context="onContextStartDate"
                  @input="onChangeGetLeaveDates(); validateHalfDayOptions()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>

            <!-- select Half Day Leave switch-->
            <b-form-group
              label="Half Day Leave"
              label-for="start_date_half_day_leave_checkbox"
            >
              <b-form-checkbox
                :checked="isCheckedStartDaySwitch"
                name="start_date_half_day_leave_checkbox"
                switch
                inline
                @change="
                  (isChecked) => {
                    onChangeStartDateSwitch(isChecked);
                    onChangeGetLeaveDates();
                    validateHalfDayOptions()
                  }
                "
              />
            </b-form-group>

            <template v-if="isCheckedStartDaySwitch">
              <!-- select Half Day Leave-->
              <b-form-group
                label="Half Day Leave"
                label-for="start_date_half_day_leave"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="start_date_half_day_leave"
                  vid="start_date_half_day_leave"
                >
                  <v-select
                    id="start_date_half_day_leave"
                    v-model="selectStartDateHalfDayLeaveValue"
                    placeholder="Choose a option"
                    :options="startDateHalfDayLeaveOption"
                    :reduce="(option) => option.value"
                    @input="onChangeGetLeaveDates"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </template>

            <!-- select end date-->
            <b-form-group
              label="End Date"
              label-for="end-date"
              class="required-label"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="end date"
                vid="end_date"
                rules="required"
              >
                <b-form-datepicker
                  id="end-date"
                  v-model="end_date"
                  :state="errors.length > 0 ? false : null"
                  locale="en-US"
                  reset-button
                  :date-disabled-fn="dateDisabled"
                  @context="onContextEndDate"
                  @input="onChangeGetLeaveDates(); validateHalfDayOptions()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>

            <!-- select Half Day Leave switch-->
            <b-form-group
              label="Half Day Leave"
              label-for="end_date_half_day_leave_checkbox"
            >
              <b-form-checkbox
                :checked="isCheckedEndDaySwitch"
                name="end_date_half_day_leave_checkbox"
                :disabled="endDateHalfDayLeaveCheckboxDisable"
                switch
                inline
                @change="
                  (isChecked) => {
                    onChangeEndDateSwitch(isChecked);
                    onChangeGetLeaveDates();
                    validateHalfDayOptions()
                  }
                "
              />
            </b-form-group>

            <template v-if="isCheckedEndDaySwitch">
              <!-- select Half Day Leave-->
              <b-form-group label-for="end_date_half_day_leave">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="end_date_half_day_leave"
                  vid="end_date_half_day_leave"
                >
                  <v-select
                    id="end_date_half_day_leave"
                    v-model="selectEndDateHalfDayLeaveValue"
                    placeholder="Choose a option"
                    :options="endDateHalfDayLeaveOption"
                    :reduce="(option) => option.value"
                    @input="onChangeGetLeaveDates"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </template>

            <!-- leave calculate -->
            <template v-if="isLeaveCalculateSubmitLoading">
              <b-form-group>
                <div class="text-center">
                  <b-spinner
                    variant="primary"
                    label="Text Centered"
                  />
                </div>
              </b-form-group>
            </template>
            <template v-else>
              <b-form-group>
                <div>
                  <p
                    v-if="leaveCount > 0"
                    class="mb-0"
                  >
                    Days: {{ leaveCount }}
                  </p>
                  <p
                    v-if="sandwichLeaveCount > 0"
                    class="mb-0"
                  >
                    Sandwich Days: {{ sandwichLeaveCount ?? 0 }}
                  </p>

                  <div
                    class="d-flex flex-wrap justify-content-start align-items-center"
                  >
                    <p class="mb-0">
                      Dates:
                      <template v-if="leaveDates.length > 0">
                        <template v-for="(leaveDate, index) in leaveDates">
                          <b-badge
                            :key="index"
                            pill
                            variant="light-primary"
                          >
                            <template v-if="leaveDate">
                              {{ leaveDate | moment("ll") }}
                            </template>
                          </b-badge>
                        </template>
                      </template>
                      <template v-else>
                        <b-badge variant="light-primary">
                          Empty
                        </b-badge>
                      </template>
                    </p>
                  </div>
                </div>
              </b-form-group>
            </template>

            <!-- remarks -->
            <b-form-group
              label="Reason"
              label-for="remarks"
              class="required-label"
            >
              <validation-provider
                #default="{ errors }"
                name="remarks"
                vid="remarks"
                rules="required"
              >
                <b-form-textarea
                  id="remarks"
                  v-model="remarks"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Reason of Leave"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- file -->

            <b-form-group
              label="Attachment"
              label-for="file"
            >
              <validation-provider
                #default="{ errors }"
                name="attachment"
                rules="size:2048"
                vid="leave_request_file"
              >
                <b-form-file
                  id="file"
                  v-model="file"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Attach a File Or Drop it Here"
                  drop-placeholder="Drop file here..."
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- select approve -->

            <b-form-group
              v-if="canApprove()"
              label="Approve"
            >
              <b-form-checkbox
                :checked="isApprove"
                name="approve"
                switch
                inline
                @change="(isChecked) => (isApprove = isChecked)"
              />
            </b-form-group>

            <!-- loading button -->
            <template v-if="isLeaveRequestFormSubmitLoading">
              <b-button
                class="float-right"
                variant="primary"
                disabled
              >
                <b-spinner small />
                Loading...
              </b-button>
            </template>

            <!-- submit button -->
            <template v-else>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                class="float-right"
                variant="primary"
              >
                Submit
              </b-button>
            </template>
          </b-form>
        </validation-observer>
      </b-modal>
    </template>
    <!-- LEAVE_REQUESTS_RECONCILIATION modal -->
    <template
      v-if="$permissionAbility(LEAVE_REQUESTS_RECONCILIATION, permissions)"
    >
      <b-modal
        id="modal-leave-reconciliation-form"
        centered
        title="Leave Reconciliation"
        hide-footer
        no-close-on-backdrop
        @hidden="hiddenModal"
      >
        <validation-observer ref="leaveReconciliationValidation">
          <!-- validationForm -->
          <b-form @submit.prevent="validationLeaveReconciliationForm">
            <!-- remarks -->
            <b-form-group
              label="Reconciliation Note"
              label-for="reconciliation-note"
            >
              <validation-provider
                #default="{ errors }"
                name="reconciliation_note"
                vid="reconciliation_note"
                rules="required"
              >
                <b-form-textarea
                  id="reconciliation-note"
                  v-model="reconciliationNote"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Reconciliation Note"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- loading button -->
            <template v-if="isLeaveReconciliationFormSubmitLoading">
              <b-button
                class="float-right"
                variant="primary"
                disabled
              >
                <b-spinner small />
                Loading...
              </b-button>
            </template>

            <!-- submit button -->
            <template v-else>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                class="float-right"
                variant="primary"
              >
                Submit
              </b-button>
            </template>
          </b-form>
        </validation-observer>
      </b-modal>
    </template>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BLink,
  BModal,
  BPagination,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import {
  LEAVE_APPROVE_ACCEPT,
  LEAVE_APPROVE_SELF_ACCEPT,
  LEAVE_REQUESTS_APPLICATION_CREATE,
  LEAVE_REQUESTS_CREATE,
  LEAVE_REQUESTS_DELETE,
  LEAVE_REQUESTS_EDIT,
  LEAVE_REQUESTS_RECONCILIATION,
} from '@/helpers/permissionsConstant'

import {
  APPROVED, CANCELED, PENDING, REJECTED,
} from '@/helpers/constant/leaveRequestStatusConstant'
import UserCard from '@/layouts/components/UserCard.vue'
import UserSelect from '@/layouts/components/UserSelect.vue'
import { required, max } from '@validations'

export default {
  name: 'LeaveRequestsView',
  components: {
    UserSelect,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    UserCard,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormDatepicker,
    BFormTextarea,
    BSpinner,
    BFormFile,
    BLink,
    BFormCheckbox,
    flatPickr
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      LEAVE_REQUESTS_CREATE,
      LEAVE_REQUESTS_EDIT,
      LEAVE_REQUESTS_DELETE,
      LEAVE_REQUESTS_APPLICATION_CREATE,
      LEAVE_REQUESTS_RECONCILIATION,
      PENDING,
      APPROVED,
      CANCELED,
      REJECTED,
      weekends: [],
      holidays: [],
      modelType: '',
      leaveRequestId: '',
      start_date: '',
      end_date: '',
      endDateHalfDayLeaveCheckboxDisable: false,
      remarks: '',
      file: null,
      selectLeaveTypeId: '',
      leaveTypesOption: [],
      employeeLeaveTypesOption: [],
      selectUserId: '',
      userIdOption: [],
      isApprove: true,
      selectStartDateHalfDayLeaveValue: 'first_half',
      startDateHalfDayLeaveOption: [
        {
          label: 'First Half',
          value: 'first_half',
        },
        {
          label: 'Second Half',
          value: 'second_half',
        },
      ],
      selectEndDateHalfDayLeaveValue: 'first_half',
      endDateHalfDayLeaveOption: [
        {
          label: 'First Half',
          value: 'first_half',
        },
        {
          label: 'Second Half',
          value: 'second_half',
        },
      ],
      isCheckedEndDaySwitch: false,
      isCheckedStartDaySwitch: false,
      isLeaveCount: false,
      leaveDates: [],
      leaveCount: 0,
      sandwichLeaveCount: 0,
      reconciliationNote: '',
      pageLength: 10,
      columns: [
        {
          label: 'Employee',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Leave Type',
          field: 'format_leave_type',
          sortable: false,
        },
        {
          label: 'Start date',
          field: 'start_date',
          sortable: false,
          formatFn: this.formatDate,
        },
        {
          label: 'End date',
          field: 'end_date',
          sortable: false,
          formatFn: this.formatDate,
        },
        {
          label: 'Leave Days',
          field: 'leave_days_format',
          sortable: false,
        },
        {
          label: 'Is Reconciliation',
          field: 'format_is_reconciliation',
          sortable: false,
          formatFn: this.formatFnTableIsReconciliation,
        },
        {
          label: 'Status',
          field: 'format_status',
          sortable: false,
        },
        {
          label: 'Created On',
          field: 'created_at',
          formatFn: this.formatDate,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      // searchTerm: "",
      // delayTimer: null,
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [{ field: 'id', type: 'desc' }],
        page: 1,
        perPage: 10,
      },
      isLeaveRequestFormSubmitLoading: false,
      isLeaveCalculateSubmitLoading: false,
      isLeaveReconciliationFormSubmitLoading: false,
    }
  },

  computed: {
    ...mapGetters({
      permissions: 'userModule/getPermissions',
    }),
  },

  watch: {
    start_date(newVal, oldVal) {
      if (this.end_date == newVal) {
        this.isCheckedEndDaySwitch = false
        this.endDateHalfDayLeaveCheckboxDisable = true
      } else {
        this.endDateHalfDayLeaveCheckboxDisable = false
      }
    },
    end_date(newVal, oldVal) {
      if (this.start_date == newVal) {
        this.isCheckedEndDaySwitch = false
        this.endDateHalfDayLeaveCheckboxDisable = true
      } else {
        this.endDateHalfDayLeaveCheckboxDisable = false
      }
    },
  },

  async created() {
    try {
      const [leaveTypes, users, weekends, holidays] = await Promise.all([
        this.getLeaveTypes(),
        this.getUsers(),
        this.getWeekendsByYear({ year: new Date().getFullYear() }),
        this.getHolidaysByYear({ year: new Date().getFullYear() }),
      ])

      // leave types
      this.leaveTypesOption = (leaveTypes?.data?.data || []).map(item => {
        const leaveOwned = item?.leave_activity?.leave_owned ?? 0
        const name = `${item?.name} (${leaveOwned}/${item?.leave_allowance})`

        let isDisable = true
        if (leaveOwned === item?.leave_allowance) {
          isDisable = false
        }

        return {
          name,
          id: item.id,
          disable: isDisable,
        }
      })

      // users
      this.userIdOption = (users?.data?.data || []).map(item => ({
        avatar: item?.avatar,
        name: item?.name,
        email: item?.email,
        mobile: item?.mobile,
        employee_number: item?.employee_number,
        id: item?.id,
        departmentId: item?.department_id,
      }))

      // weekends
      this.weekends = (weekends?.data?.data || [])
        .map(item => {
          const joinDate = new Date(item?.date).toISOString().slice(0, 10)
          return joinDate
        })
        .sort((a, b) => new Date(a) - new Date(b))

      // holidays
      this.holidays = (holidays?.data?.data || [])
        .map(item => {
          const joinDate = new Date(item?.date).toISOString().slice(0, 10)
          return joinDate
        })
        .sort((a, b) => new Date(a) - new Date(b))
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'BellIcon',
          variant: 'danger',
          text: error?.response?.data?.message,
        },
      })
    }
  },

  methods: {
    daysBetweenDates(startDate, endDate) {
      const start = new Date(startDate)
      const end = new Date(endDate)

      // Calculate the difference in time
      const diffInTime = end.getTime() - start.getTime()

      // Calculate the difference in days
      return Math.ceil(diffInTime / (1000 * 60 * 60 * 24)) + 1
    },
    // employee cannot apply for half day while has a leave break in between leave time validation
    validateHalfDayOptions() {

      const defaultOptions = [
        { label: 'First Half', value: 'first_half' },
        { label: 'Second Half', value: 'second_half' },
      ]

      this.startDateHalfDayLeaveOption = defaultOptions
      this.endDateHalfDayLeaveOption = defaultOptions

      // Reset options to default
      const resetOptions = (isStart, isEnd) => {
        if (isStart) {
          this.selectStartDateHalfDayLeaveValue = ''
          this.startDateHalfDayLeaveOption = defaultOptions.filter(option => option.value === 'second_half')
        }
        if (isEnd) {
          this.selectEndDateHalfDayLeaveValue = ''
          this.endDateHalfDayLeaveOption = defaultOptions.filter(option => option.value === 'first_half')
        }
      }

      const {
        start_date: startDate, end_date: endDate,
      } = this

      if (!startDate || !endDate) return

      const leaveDays = this.daysBetweenDates(startDate, endDate)
      if (leaveDays <= 1) return

      console.log('here')

      if (leaveDays > 1) {
        resetOptions(true, true)
      }

    },
    canApprove() {
      if (
        this.$permissionAbility(LEAVE_APPROVE_ACCEPT, this.permissions)
          && this.$permissionAbility(LEAVE_APPROVE_SELF_ACCEPT, this.permissions)
      ) {
        return true
      }

      return false
    },
    statusVariant(value) {
      if (value === this.APPROVED) {
        return 'light-success'
      } if (value === this.REJECTED) {
        return 'light-danger'
      } if (value === this.PENDING) {
        return 'light-warning'
      }
      return 'light-warning'
    },
    formatFnTableIsReconciliation(value) {
      if (value) {
        return 'Yes'
      }
    },
    formatFnTableFormateDate(value) {
      if (value) {
        return this.$moment(value).format('ll')
      }
    },
    async onChangeGetLeaveDates(event) {
      try {
        this.leaveDates = []
        this.leaveCount = 0
        this.sandwichLeaveCount = 0

        if (this.start_date && this.end_date && this.selectLeaveTypeId) {
          this.isLeaveCalculateSubmitLoading = true
          let start_date
          let end_date
          let leave_type_id
          let start_date_half_day_leave
          let end_date_half_day_leave

          if (this.start_date) {
            start_date = this.start_date
          }

          if (this.end_date) {
            end_date = this.end_date
          }

          if (this.selectLeaveTypeId) {
            leave_type_id = this.selectLeaveTypeId
          }

          if (
            this.isCheckedStartDaySwitch
            && this.selectStartDateHalfDayLeaveValue
          ) {
            start_date_half_day_leave = this.selectStartDateHalfDayLeaveValue
          }

          if (
            this.isCheckedEndDaySwitch
            && this.selectEndDateHalfDayLeaveValue
          ) {
            end_date_half_day_leave = this.selectEndDateHalfDayLeaveValue
          }

          const leaveCalculate = await this.getCalculatedLeaveDates({
            leave_type_id,
            start_date,
            end_date,
            start_date_half_day_leave,
            end_date_half_day_leave,
          })

          this.leaveDates = (leaveCalculate?.data?.data?.leaveDates || []).sort(
            (a, b) => new Date(a) - new Date(b),
          )
          this.leaveCount = leaveCalculate?.data?.data?.leaveCount
          this.sandwichLeaveCount = leaveCalculate?.data?.data?.finalSandwichCount
          this.isLeaveCalculateSubmitLoading = false
        }
      } catch (error) {
        this.isLeaveCalculateSubmitLoading = false
        this.leaveDates = []
        this.leaveCount = 0
        this.sandwichLeaveCount = 0
        if (error?.response?.data?.message) {
          this.$toast({
            component,
            ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              variant: 'danger',
              text: error?.response?.data?.message,
            },
          })
        }

        if (error?.response?.data?.errors) {
          this.$refs.leaveRequestValidation.setErrors(
            error?.response?.data?.errors,
          )
        }
      }
    },
    async getCalculatedLeaveDates(params) {
      return await this.$api.get('api/leave-approve/leave-calculate', {
        params: {
          leave_type_id: params?.leave_type_id,
          start_date: params?.start_date,
          end_date: params?.end_date,
          start_date_half_day_leave: params?.start_date_half_day_leave,
          end_date_half_day_leave: params?.end_date_half_day_leave,
        },
      })
    },
    dateDisabled(ymd, date) {
      // day
      let day = date.getDate()
      const dayString = day.toString()
      if (dayString.length === 1) {
        day = `0${day}`
      }

      // month
      let month = date.getMonth() + 1
      const monthString = month.toString()
      if (monthString.length === 1) {
        month = `0${month}`
      }

      // year
      const year = date.getFullYear()
      const joinDate = [year, month, day].join('-')

      return (
        this.weekends.includes(joinDate.toString())
        || this.holidays.includes(joinDate.toString())
      )
    },

    dateDisabledEmp(ymd, date) {
      // Get current date
      const currentDate = new Date()
      currentDate.setHours(0, 0, 0, 0) // Set time to midnight for accurate comparison

      // Check if the given date is in the past (excluding today)
      if (date < currentDate) {
        return true
      }

      // day
      let day = date.getDate()
      const dayString = day.toString()
      if (dayString.length === 1) {
        day = `0${day}`
      }

      // month
      let month = date.getMonth() + 1
      const monthString = month.toString()
      if (monthString.length === 1) {
        month = `0${month}`
      }

      // year
      const year = date.getFullYear()
      const joinDate = [year, month, day].join('-')

      return (
        this.weekends.includes(joinDate.toString())
        || this.holidays.includes(joinDate.toString())
      )
    },

    async onChangeEmployee(value) {
      try {
        this.selectLeaveTypeId = ''
        const leaveTypes = await this.getLeaveTypes({ user_id: value })
        // leave types
        this.employeeLeaveTypesOption = (leaveTypes?.data?.data || []).map(
          item => {
            const leaveOwned = item?.leave_activity?.leave_owned ?? 0
            const name = `${item?.name} (${leaveOwned}/${item?.leave_allowance})`

            let isDisable = true
            if (leaveOwned === item?.leave_allowance) {
              isDisable = false
            }

            return {
              name,
              id: item.id,
              disable: isDisable,
            }
          },
        )
      } catch (error) {
        if (error?.response?.data?.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              variant: 'danger',
              text: error?.response?.data?.message,
            },
          })
        }
      }
    },
    onChangeStartDateSwitch(value) {
      this.isCheckedStartDaySwitch = value
    },
    onChangeEndDateSwitch(value) {
      this.isCheckedEndDaySwitch = value
    },
    tableLeaveType(rowObj) {
      return rowObj?.leaveType?.data?.name
    },
    onContextStartDate(ctx) {
      this.start_date = ctx.selectedYMD
    },
    onContextEndDate(ctx) {
      this.end_date = ctx.selectedYMD
    },
    showModal() {
      this.$bvModal.show('modal-leave-request-form')
    },
    showApplicationCreateModal() {
      this.$bvModal.show('modal-leave-application-create-form')
    },
    showReconciliationModal() {
      this.$bvModal.show('modal-leave-reconciliation-form')
    },
    hiddenModal() {
      this.$bvModal.hide('modal-leave-request-form')
      this.resetModal()
    },
    hiddenApplicationCreateModal() {
      this.$bvModal.hide('modal-leave-application-create-form')
      this.resetModal()
    },
    hiddenReconciliationModal() {
      this.$bvModal.hide('modal-leave-reconciliation-form')
      this.resetModal()
    },
    resetModal() {
      this.modelType = ''
      this.start_date = ''
      this.end_date = ''
      this.remarks = ''
      this.file = null
      this.selectLeaveTypeId = ''
      this.leaveRequestId = ''
      this.selectStartDateHalfDayLeaveValue = 'first_half'
      this.selectEndDateHalfDayLeaveValue = 'first_half'
      this.isCheckedStartDaySwitch = false
      this.isCheckedEndDaySwitch = false
      this.endDateHalfDayLeaveCheckboxDisable = false
      this.isApprove = true
      this.selectUserId = ''
      this.selectLeaveTypeId = ''
      this.employeeLeaveTypesOption = []
      this.leaveDates = []
      this.leaveCount = 0
      this.sandwichLeaveCount = 0
      this.reconciliationNote = ''
    },
    async onShow(value) {
      this.modelType = 'editModel'

      this.leaveRequestId = value?.id
      this.start_date = value?.start_date
      this.end_date = value?.end_date
      this.remarks = value?.remarks
      this.selectLeaveTypeId = value?.leave_type_id

      this.selectStartDateHalfDayLeaveValue = value?.start_date_half_day_leave
      this.selectEndDateHalfDayLeaveValue = value?.end_date_half_day_leave

      this.isCheckedStartDaySwitch = !!value?.start_date_half_day_leave
      this.isCheckedEndDaySwitch = !!value?.end_date_half_day_leave

      const leaveCalculate = await this.getCalculatedLeaveDates({
        leave_type_id: value?.leave_type_id,
        start_date: value?.start_date,
        end_date: value?.end_date,
        start_date_half_day_leave: value?.start_date_half_day_leave,
        end_date_half_day_leave: value?.end_date_half_day_leave,
      })

      this.leaveDates = (leaveCalculate?.data?.data?.leaveDates || []).sort(
        (a, b) => new Date(a) - new Date(b),
      )
      this.leaveCount = leaveCalculate?.data?.data?.leaveCount
      this.sandwichLeaveCount = leaveCalculate?.data?.data?.finalSandwichCount

      this.showModal()
    },
    async onDelete(id) {
      this.$swal({
        title: 'Warning!',
        text: 'Are You Sure You Want To Delete This?',
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: 'Ok',
        showLoaderOnConfirm: true,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/leave-requests/${id}`)

            this.loadItems()

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Successfully Deleted',
              },
            })
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                variant: 'danger',
                text: error?.response?.data?.message,
              },
            })
          }
        }
      })
    },
    onReconciliation(value) {
      this.leaveRequestId = value?.id
      this.reconciliationNote = value?.reconciliation_note
      this.showReconciliationModal()
    },

    // onSearch() {
    //   if (this.delayTimer) {
    //     clearTimeout(this.delayTimer);
    //     this.delayTimer = null;
    //   }

    //   this.delayTimer = setTimeout(() => {
    //     this.loadItems();
    //   }, 1000);
    // },

    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },
    async getUsers() {
      return await this.$api.get('api/users/active-all?include=designation')
    },
    async getHolidaysByYear(params) {
      try {
        return await this.$api.get('api/holidays/get-by-year', {
          params: {
            year: params?.year,
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },
    async getWeekendsByYear(params) {
      try {
        return await this.$api.get('api/weekends/get-by-year', {
          params: {
            year: params.year,
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },
    async getLeaveRequests(params) {
      return await this.$api.get('api/leave-requests?include=leaveType,user', {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
        },
      })
    },
    async getLeaveTypes(params) {
      return await this.$api.get('api/leave/balance', {
        params: {
          user_id: params?.user_id,
        },
      })
    },

    async loadItems() {
      try {
        const [leaveRequests] = await Promise.all([
          this.getLeaveRequests({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
          }),
        ])

        const data = leaveRequests?.data?.data
        const meta = leaveRequests?.data?.meta

        this.totalRecords = meta?.pagination?.total
        this.rows = data
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },
    async validationForm() {
      this.$refs.leaveRequestValidation.validate().then(async success => {
        if (success) {
          try {
            if (this.modelType == 'editModel') {
              this.isLeaveRequestFormSubmitLoading = true

              const formData = new FormData()

              formData.append('_method', 'PUT')

              if (this.file) {
                formData.append('leave_request_file', this.file)
              }

              if (this.start_date) {
                formData.append('start_date', this.start_date)
              }

              if (this.end_date) {
                formData.append('end_date', this.end_date)
              }

              if (this.remarks) {
                formData.append('remarks', this.remarks)
              }

              if (this.leaveCount > 0) {
                formData.append('leave_days', +this.leaveCount)
              }

              if (this.selectLeaveTypeId) {
                formData.append('leave_type_id', this.selectLeaveTypeId)
              }

              if (
                this.isCheckedStartDaySwitch
                && this.selectStartDateHalfDayLeaveValue
              ) {
                formData.append(
                  'start_date_half_day_leave',
                  this.selectStartDateHalfDayLeaveValue,
                )
              }

              if (
                this.isCheckedEndDaySwitch
                && this.selectEndDateHalfDayLeaveValue
              ) {
                formData.append(
                  'end_date_half_day_leave',
                  this.selectEndDateHalfDayLeaveValue,
                )
              }

              await this.$api.post(
                `/api/leave-requests/${this.leaveRequestId}`,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                },
              )
              this.isLeaveRequestFormSubmitLoading = false
              this.loadItems()
              this.hiddenModal()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: 'Successfully Updated',
                },
              })
            } else {
              this.isLeaveRequestFormSubmitLoading = true

              const formData = new FormData()

              if (this.file) {
                formData.append('leave_request_file', this.file)
              }

              if (this.start_date) {
                formData.append('start_date', this.start_date)
              }

              if (this.end_date) {
                formData.append('end_date', this.end_date)
              }

              if (this.remarks) {
                formData.append('remarks', this.remarks)
              }

              if (this.leaveCount > 0) {
                formData.append('leave_days', +this.leaveCount)
              }

              if (this.selectLeaveTypeId) {
                formData.append('leave_type_id', this.selectLeaveTypeId)
              }

              if (
                this.isCheckedStartDaySwitch
                && this.selectStartDateHalfDayLeaveValue
              ) {
                formData.append(
                  'start_date_half_day_leave',
                  this.selectStartDateHalfDayLeaveValue,
                )
              }

              if (
                this.isCheckedEndDaySwitch
                && this.selectEndDateHalfDayLeaveValue
              ) {
                formData.append(
                  'end_date_half_day_leave',
                  this.selectEndDateHalfDayLeaveValue,
                )
              }

              await this.$api.post('/api/leave-requests', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
              this.isLeaveRequestFormSubmitLoading = false
              this.hiddenModal()

              this.loadItems()

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: 'Successfully Created',
                },
              })
            }
          } catch (error) {
            this.isLeaveRequestFormSubmitLoading = false
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'BellIcon',
                  variant: 'danger',
                  text: error?.response?.data?.message,
                },
              })
            }

            if (error?.response?.data?.errors) {
              this.$refs.leaveRequestValidation.setErrors(
                error?.response?.data?.errors,
              )
            }
          }
        }
      })
    },
    async applicationCreateForm() {
      this.$refs.leaveApplicationCreateValidation
        .validate()
        .then(async success => {
          if (success) {
            try {
              this.isLeaveRequestFormSubmitLoading = true

              const formData = new FormData()

              if (this.selectUserId) {
                formData.append('user_id', this.selectUserId)
              }

              formData.append('status', this.isApprove)

              if (this.file) {
                formData.append('leave_request_file', this.file)
              }

              if (this.start_date) {
                formData.append('start_date', this.start_date)
              }

              if (this.end_date) {
                formData.append('end_date', this.end_date)
              }

              if (this.remarks) {
                formData.append('remarks', this.remarks)
              }

              if (this.leaveCount > 0) {
                formData.append('leave_days', +this.leaveCount)
              }

              if (this.selectLeaveTypeId) {
                formData.append(
                  'leave_type_id',
                  this.selectLeaveTypeId,
                )
              }

              if (
                this.isCheckedStartDaySwitch
                && this.selectStartDateHalfDayLeaveValue
              ) {
                formData.append(
                  'start_date_half_day_leave',
                  this.selectStartDateHalfDayLeaveValue,
                )
              }

              if (
                this.isCheckedEndDaySwitch
                && this.selectEndDateHalfDayLeaveValue
              ) {
                formData.append(
                  'end_date_half_day_leave',
                  this.selectEndDateHalfDayLeaveValue,
                )
              }

              await this.$api.post(
                '/api/leave-approve/application/create',
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                },
              )
              this.isLeaveRequestFormSubmitLoading = false
              this.hiddenApplicationCreateModal()

              this.loadItems()

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: 'Successfully Created',
                },
              })
            } catch (error) {
              this.isLeaveRequestFormSubmitLoading = false
              if (error?.response?.data?.message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: error?.response?.data?.message,
                  },
                })
              }

              if (error?.response?.data?.errors) {
                this.$refs.leaveApplicationCreateValidation.setErrors(
                  error?.response?.data?.errors,
                )
              }
            }
          }
        })
    },
    async validationLeaveReconciliationForm() {
      this.$refs.leaveReconciliationValidation
        .validate()
        .then(async success => {
          if (success) {
            try {
              this.isLeaveReconciliationFormSubmitLoading = true

              await this.$api.post(
                `api/leave-requests/${this.leaveRequestId}/reconciliation`,
                {
                  reconciliation_note: this.reconciliationNote,
                },
              )
              this.isLeaveReconciliationFormSubmitLoading = false
              this.hiddenReconciliationModal()

              this.loadItems()

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: 'Successfully Created',
                },
              })
            } catch (error) {
              this.isLeaveReconciliationFormSubmitLoading = false
              if (error?.response?.data?.message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: error?.response?.data?.message,
                  },
                })
              }

              if (error?.response?.data?.errors) {
                this.$refs.leaveRequestValidation.setErrors(
                  error?.response?.data?.errors,
                )
              }
            }
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.table-custom-style {
  font-size: 14px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: middle !important;
    //text-align: center !important;
  }
}

.flatpickr-input[readonly]:disabled {
  color: #d3d3d3 !important; /* Custom color (light gray in this case) */
  background-color: transparent !important;
  cursor: not-allowed;
}
</style>
